.contactFormContainer {
    z-index: 30;
}
.contactFormSent {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
}
.contactFormSentShow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactFormMessage {
    flex: 1 1 auto;
    min-height: 3rem;
    overflow: auto;
}

.contactFormLegalFirstLayer {
    padding: 0 0.7rem;
    font-size: 80%;
}

.contactFormButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contactFormSending {
    text-align: center;
    font-style: italic;
}

.contactFormSentResultContainer {
    text-align: center;
}
.contactFormSentMsg {
    padding: 2rem 0;
    max-width: 30rem;
}