.faqContainer {
    background-color: white;
    padding: 3rem;
}

.faqQuestionContainer {
    margin: auto;
    max-width: 65rem;

    box-sizing: border-box;
}

.faqContainerTitle {
    font-weight: bolder;
    font-size: 200%;
    text-align: center;

    padding: 2rem 0;
}

.faqQuestion {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    padding: 1rem 0.5rem;

    font-size: 125%;
    font-weight: bolder;

    cursor: pointer;
}

.faqAnswer {
    padding: 0 1rem;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.25s ease-out;
}
.faqAnswerActive {
    max-height: 100vh;
    transition: max-height 0.25s ease-in;
}

.contactButton {
    display: flex;
    justify-content: center;

    padding: 0 0.2rem 3rem 0.2rem;
}

div.contactTitle {
    margin-top: 3rem;
}