div.presentationContainer {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 85vh;

    padding-top: calc(var(--nav-Bar-height) + 5vh);
    box-sizing: border-box;

    text-align: center;
    color: white;
}
div.presentationSlogan {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0.5rem;

    font-size: 5vh;
}
div.presentationDescription {
    flex: 1 1 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0.5rem;

    font-size: 2.5vh;
}
div.presentationSpacer {
    flex: 2 1 auto;
    min-height: 7vh;
}

a.presentationCenteredButton {
    display: block;
    margin: 0 auto;
}

div.presentationText{
    flex: 1 1 auto;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1.5rem 0.5rem;

    font-size: 0.9rem;
}

