.lawList {

}

.lawListElement {

}

ul.lawList {
    height: 100%;
    min-height: 3rem;
    overflow: auto;
}

.lawListSpinnerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-style: italic;

    height: 25vh;
}
.lawListSpinner {
    height: 3rem;
    width: 3rem;
}

.lawListElement {
    width: 100%;

    border: 1px solid rgb(236, 236, 236);
    background-color: rgb(248, 248, 248);

    margin: 0.1rem 0;
    padding: 0.75rem 0.5rem;
    box-sizing: border-box;

    cursor: pointer;

    font-size: 85%;

    transition: box-shadow 0.25s linear;
}
.lawListElement:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0 0 15px;
    position: relative;
}

.lawListLawDate {
    padding-top: 0.25rem;
    font-style: italic;
    font-size: 80%;
}

input.lawListInputFilter {
    width: 100%;
    box-sizing: border-box;
    margin: 0.5rem 0 0 0;
}

li.lawListContact {
    text-align: center;
    font-size: 80%;
    padding: 0.5rem 0 0.5rem 0;
    font-style: italic;
}

button.lawListOpenContact {
    display: inline;
    padding: 0;
    margin: 0;
    letter-spacing: 0;
    font-size: inherit;
    font-style: italic;
}

a.lawListLink {
    color: inherit;
    outline: 0;
}