.footerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    background-color: none;
    width: 100%;
    padding: 1rem 0rem 1rem 0.5rem;
    box-sizing: border-box;
    
}

a.footerLegal, span.footerLegal {
    padding: 0.5rem 1rem;
    color: #dedede;
}