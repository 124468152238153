.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    top: 0;

    width: 100%;
    height: var(--nav-Bar-height);

    background-color: rgba(var(--secondary-color), 0);
    transition: background-color 0.25s linear 0s;

    z-index: 10;
}
.headerContainerDark {
    background-color: rgba(var(--secondary-color), 1);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1), 0 6px 6px rgba(0,0,0,0.1);
}

div.headerUserSection {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    padding: 0.5rem;
    box-sizing: border-box;

    cursor: pointer;
}
button.headerUserText {
    padding-right: 0;
    margin: 0;
}
.headerUserIcon {
    fill: rgba(var(--primary-color), 1);
    height: 100%;
    width: auto;
}
@media screen and (max-width: 700px) {
    button.headerUserText {
        display: none;
    }
}