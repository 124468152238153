.backgroundContainer {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(var(--primary-color), 1);

    background-image: url("/public/headphones_book_x2.jpg");
    background-image: image-set(
        url("/public/headphones_book_x1.webp") 1x,
        url("/public/headphones_book_x2.jpg") 2x,
        url("/public/headphones_book_x2.webp") 2x);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 100vh rgb(0 0 0 / 60%);

    z-index: -1;
    overflow: none;
}

.backgroundImg {
    flex: 0 0 auto;
    min-width: 100%;
    min-height: 100%;
    filter: brightness(40%);
}