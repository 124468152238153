#logoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    height: 100%;
    width: auto;
    min-width: 0;
}
.logoIcon {
    flex: 0 1 auto;
    height: 100%;
    width: auto;
    padding: 0.5rem;
    box-sizing: border-box;
}
.logoLabel {
    flex: 0 1 auto;
    height: 75%;
    width: auto;
    padding: 0.3rem 0.5rem;
    box-sizing: border-box;
    fill: white;
}