div.whatsappContainer {
    position: fixed;
    display: block;
    bottom: 0;
    right: 0;

    padding: 1rem;

    text-align: right;

    z-index: 2;
}
a.whatsappLink {
    display: block;
    border-radius: 100%;
    outline: none;
    height: 2rem;
    width: 2rem;
    padding: 0.5rem;

    transition: all linear 200ms;

    box-shadow: 0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20);

    background-color: #25D366;
}
.whatsappIcon {
    width: 2rem;
    fill: #FFFFFF;
}
a.whatsappLink:hover {

    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.14), 0 6px 6px -4px rgba(0,0,0,0.12), 0 2px 16px 0 rgba(0,0,0,0.20);
}