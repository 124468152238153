@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Thin.woff2) format('woff2');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Montserrat-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Open-Sans-400.woff2) format('woff2');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/fonts/Open-Sans-400.woff2) format('woff2');
}

html, body, .root {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
html {
  overflow-x: hidden;
}
:root {
  --nav-Bar-height: 4rem;
  --primary-color: 221, 89, 0;
  --secondary-color: 68, 29, 2;
  --primary-color-visited-link: 171, 89, 0;
  --menu-transition-time: 0.25s;
  --player-color: 134, 134, 134;
  --presentation-min-height: 50vh;
}

a {
  text-decoration: none;
  color: rgba(var(--primary-color), 1);
}

button.rcl, input.rcl, a.rcl {
  display: inline-block;
  padding: 0.7rem 1.4rem;
  margin: 0 0.3rem 0.3rem 0;
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-variant: small-caps;
  font-weight: 400;
  font-size: 100%;
  color: #FFFFFF;
  outline: none;
  text-align: center;
  position: relative;
  border: none;
  cursor: pointer;
  letter-spacing: 0.2rem;
  background-color: rgba(var(--primary-color), 1);
  box-shadow: inset 0 -0.6rem 0 -0.35rem rgba(var(--secondary-color), 0.15);
  appearance: none;
}
button.rcl:active, input.rcl:active, a.rcl:active {
  top: 0.1rem;
  box-shadow: none;
}
button.rclSecondary, input.rclSecondary, a.rclSecondary {
  background: none;
  color: rgba(var(--primary-color), 1);
  font-weight: 400;
  font-size: 110%;
  box-shadow: none;
}
button.rclSecondaryDark, input.rclSecondaryDark, a.rclSecondaryDark {
  background-color: #b0b0b0;
}

li {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  padding: 0.7rem;
  margin: 0.7rem;
  font-family: inherit;
  font-size: inherit;
}
input::placeholder, textarea::placeholder {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  color: rgba(0, 0, 0, 0.5)
}

.okLogo {
  color: green;
  font-size: 800%;
  font-weight: bolder;
  user-select: none;
}
.koLogo {
  color: red;
  font-size: 800%;
  font-weight: bolder;
  user-select: none;
}

div.mainCard {
  max-width: 70rem;

  margin: 2rem auto;

  padding: 3rem 1rem;
  border-radius: 20px;
}

.cardShadow {
  box-shadow: 0 10px 20px rgba(0,0,0,0.5), 0 6px 6px rgba(0,0,0,0.5);
}