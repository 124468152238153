div.descriptionCard {
    background-color: white;
}
div.descriptionContainer {
    max-width: 65rem;
    margin: auto;
}
div.descriptionHeader {
    padding: 1rem;
    text-align: center;
    font-size: 2rem;
}
div.descriptionSeparation {
    padding-top: 4rem;
}
div.descriptionContent {
    padding: 0.75rem 1.5rem;
}
div.descriptionContentCentered {
    text-align: center;
    font-style: italic;
}

a.descriptionContentLink {
    text-decoration: none;
    color: rgba(var(--primary-color), 1);
    text-align: center;
    display: block;
    font-style: italic;
    padding-bottom: 0.2rem;
}

div.descriptionContentCentered {
    text-align: center;
    padding-top: 0;
}

button.descriptionPlayButton {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: auto;
    padding: 0.5rem;
    box-sizing: content-box;
}
.descriptionPlayIcon {
    fill: white;
    height: 100%;
    width: 100%;
}
.descriptionPlayIconSpinner {
    margin: auto;
    height: 70%;
    width: 70%;
    border-top: 2px solid rgba(255, 255, 255, 1);
    border-left: 2px solid rgba(255, 255, 255, 0.3);
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    border-right: 2px solid rgba(255, 255, 255, 0.3);
}

div.descriptionCenteredContainer {
    display: flex;
    margin: 1rem auto 0 auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

button.descriptionCenteredButton {
    display: block;
    margin: 1rem auto 0 auto;
}

strong {
    font-weight: 700;
}