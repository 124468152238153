div.featuresContainer {
    background-color: rgba(var(--secondary-color), 1);
    color: #dedede;
}

div.featuresFlexContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-evenly;
    flex-wrap: wrap;

    margin: auto;

    max-width: 65rem;
}

div.featureContainer {
    display: inline-block;
    padding: 2.5rem 2rem;
    width: 100%;
    max-width: 20rem;
}
.featuresIcon {
    display: block;
    margin: auto;
    height: 5rem;
    width: 5rem;
    fill: rgba(var(--primary-color), 1);
}
div.featuresText {
    margin: auto;
    text-align: center;
}

div.featuresCallToAction {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 1rem;
}